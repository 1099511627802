import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		loading: true,
		routerView: [],
	},
	getters: {
		hasView: (state) => {
			return state.routerView
		},
		isLoading: (state) => {
			return state.loading
		},
	},
	mutations: {
		SET_VIEW: (state, view) => {
			state.routerView = view
		},
		SET_LOAD: (state, view) => {
			state.loading = view
		},
	},
	actions: {
		saveView({ commit }, data) {
			return new Promise((resolve) => {
				commit('SET_VIEW', data)
				resolve()
			})
		},
		saveLoad({ commit }, data) {
			return new Promise((resolve) => {
				commit('SET_LOAD', data)
				resolve()
			})
		},
	},
	modules: {},
})
