<template>
	<footer v-if="info">
		<div class="container m_flex_box flex_jz flex_vc">
			<div class="left">
				<img src="@/assets/img/logo_bs.png" alt="" />
				<ul>
					<li>版权所有：{{ info.copyright }}</li>
					<li>地址：{{ info.address }}</li>
					<li>
						备案：<a
							target="_blank"
							href="https://beian.miit.gov.cn/"
						>
							{{ info.filing }}</a
						>
					</li>
				</ul>
			</div>
			<ul class="middle">
				<li class="m_flex_box flex_vc">
					<img src="@/assets/img/icon_yx.png" alt="" />
					<div>
						<p class="text">{{ info.email }}</p>
						<p class="tit">服务邮箱</p>
					</div>
				</li>
				<li class="m_flex_box flex_vc">
					<img src="@/assets/img/icon_sj_b.png" alt="" />
					<div>
						<p class="text">
							{{ info.phone }}
						</p>
						<p class="tit">服务电话</p>
					</div>
				</li>
				<li class="m_flex_box flex_vc">
					<img src="@/assets/img/icon_dh.png" alt="" />
					<div>
						<p class="text">
							{{ info.serviceStartTime }}-{{
								info.serviceStartEnd
							}}
						</p>
						<p class="tit">服务时间</p>
					</div>
				</li>
			</ul>
			<div class="right m_flex_box flex_vc">
				<img :src="info.qrcode" class="code" alt="" />
				<div class="logo">
					<img src="@/assets/img/logo.png" alt="" />
					<p>扫描二维码关注</p>
					<p>徐州市政集团公众号</p>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	data() {
		return {
			info: null,
		}
	},
	mounted() {
		this.getInfo()
	},
	methods: {
		getInfo() {
			this.$http.get('home/companyInfo.do').then((res) => {
				this.info = res.result
			})
		},
	},
}
</script>

<style lang="scss" scoped>
footer {
	background-color: #0c1e2f;
	color: #fff;
	padding: 40px 0 60px;
	min-width: 1280px;
}
li {
	list-style: none;
}
.left {
	img {
		margin-bottom: 50px;
	}
	li {
		color: #b2b6b8;
		margin-bottom: 18px;
		font-size: 14px;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
.middle {
	li {
		&:not(:last-child) {
			margin-bottom: 36px;
		}
	}
	img {
		margin-right: 8px;
	}
	.text {
		font-size: 16px;
	}
	.tit {
		font-size: 14px;
		color: #b2b6b8;
	}
}
.right {
	border: 1px solid #1f303f;
	padding: 16px;
	.code {
		width: 150px;
		height: 150px;
		margin-right: 30px;
	}
	.logo {
		text-align: center;
		img {
			margin: 0 auto 16px;
			width: 67px;
		}
		font-size: 14px;
		color: #b2b6b8;
	}
}
</style>
