import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import wow from 'wowjs'
import 'wowjs/css/libs/animate.css'
import mixin from '@/utils/mixin.js'
import 'styles/style.scss'
import http from '@/utils/request'
import * as filters from '@/utils/filters.js'

Vue.prototype.$http = http
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$wow = wow
Vue.mixin(mixin)
Object.keys(filters).forEach((key) => {
	Vue.filter(key, filters[key])
})

new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app')
