const mixins = {
	data() {
		return {}
	},
	methods: {
		initWow() {
			const wow = new this.$wow.WOW({
				boxClass: 'wow',
				animateClass: 'animated',
				offset: 0,
				mobile: true,
				live: true,
			})
			wow.init()
		},
		goBack() {
			this.$router.back()
		},
	},
}
export default mixins
