import axios from 'axios'
import { Message, MessageBox, Loading, Notification } from 'element-ui'
import router from '../router'
import store from '@/store'
import domMessage from './messageOnce'
const messageOnce = new domMessage()
// create an axios instance
axios.defaults.withCredentials = true
axios.defaults.crossDomain = true
const service = axios.create({
	baseURL:
		process.env.NODE_ENV === 'development' ? '/api/' : '/xzszjava/yw/api/', // api 的 base_url
	// headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
	timeout: 50000, // request timeout
})
const loadimgTime = 100
let loadingInstance
// request interceptor
service.interceptors.request.use(
	(config) => {
		loadingInstance = Loading.service({
			lock: false,
			text: '加载中',
			background: 'rgba(0,0,0,0.4)',
			customClass: 'loading-color',
		})
		if (store.getters.token) {
			// 让每个请求携带token
			config.headers['token'] = store.getters.token
			config.headers['client'] = 'pc'
			// config.headers['userId'] = userInfo.userId;
		} else {
			config.headers['client'] = 'pc'
			config.headers['token'] = ''
			config.headers['userId'] = ''
		}
		return config
	},
	(error) => {
		messageOnce.warning({
			message: '加载超时',
			duration: 3 * 1000,
		})
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	(response) => {
		setTimeout(() => {
			loadingInstance.close()
		}, loadimgTime)
		if (response.config.responseType === 'blob') {
			return response
		} else {
			const res = response.data
			if (response.status !== 200) {
				if (response.status === 500) {
					Notification.close()
					messageOnce.warning({
						title: '系统提示',
						message: '服务器异常，请稍后重试',
					})
				} else {
					Notification.close()
					messageOnce.error({
						title: '系统提示',
						message: res.message,
					})
				}
				return Promise.reject(res)
			} else {
				if (res.code == 200) {
					return res
				} else {
					if (res.code === 800) {
						return res
					}
					if (res.code === 10300) {
						MessageBox.alert(res.message, '系统提示', {
							confirmButtonText: '确定',
							callback: (action) => {
								sessionStorage.clear()
								window.location.reload()
							},
						})
						return Promise.reject(res)
					}
					if (res.code === 10800) {
						Notification.close()
						messageOnce.warning({
							title: '注意',
							message: '身份信息失效或在其他地方登录，请重新登录',
						})
						localStorage.removeItem('token')
						localStorage.removeItem('userInfo')
						store.dispatch('saveToken', '')
						store.dispatch('saveInfo', {})
						if (localStorage.getItem('xzBack') == 'true') {
							localStorage.removeItem('xzBack')
							router.push({
								path: '/login',
								query: {
									back: 'true',
								},
							})
						} else {
							router.push({
								path: '/login',
							})
						}
						return Promise.reject(res)
					}
					if (res.code === 10430) {
						messageOnce.warning({
							title: '注意',
							message: res.message,
						})
						localStorage.removeItem('token')
						localStorage.removeItem('userInfo')
						store.dispatch('saveToken', '')
						store.dispatch('saveInfo', {})
						return Promise.reject(res)
					}
					Notification.close()
					messageOnce.error({
						title: '注意',
						message: res.message,
					})
					return Promise.reject(res)
				}
			}
		}
	},
	(error) => {
		messageOnce.warning({
			title: '提示',
			message: error.message,
			duration: 2 * 1000,
			offset: 200,
		})
		setTimeout(() => {
			loadingInstance.close()
		}, loadimgTime)
		return Promise.reject(error)
	}
)

export default service
