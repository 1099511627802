<template>
	<div id="app">
		<Navbar></Navbar>
		<router-view />
		<FooterCon></FooterCon>
		<loading v-if="isLoading"></loading>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import Navbar from '@/components/navbar.vue'
import FooterCon from '@/components/footer_con.vue'
import loading from '@/components/loading'
export default {
	data() {
		return {}
	},
	computed: {
		...mapGetters(['isLoading']),
	},
	components: {
		Navbar,
		FooterCon,
		loading,
	},
  created() {
    this.toWeb()
  },
  methods: {
    toWeb() {
      var userAgent = navigator.userAgent.toLowerCase();
      var platform;
      // return
      if (userAgent == null || userAgent == '') {
          platform = 'WEB';
      } else {
        if (userAgent.indexOf("android") != -1) {
          platform = 'ANDROID';
          location.href = window.location.protocol + '//' + window.location.host + "/xzszWeb/#/";
        } else if (userAgent.indexOf("ios") != -1 || userAgent.indexOf("iphone") != -1 || userAgent.indexOf("ipad") != -1) {
          platform = 'IOS';
          location.href = window.location.protocol + '//' + window.location.host + "/xzszWeb/#/";
        } else if (userAgent.indexOf("windows phone") != -1) {
          platform = 'WP';
          location.href = window.location.protocol + '//' + window.location.host + "/xzszWeb/#/";
        } else {
          platform = 'WEB';
          // location.href = "https://esftest.xzhouse.com.cn/page/generation/xzxxw/#/xzHouseM.html";
        }
      }
    }
  }
}
</script>
<style lang="scss"></style>
