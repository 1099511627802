const companyProfile = [
	{
		path: '/project',
		name: 'project',
		component: () => import('@/views/project/index.vue'),
		meta: {
			title: '精品工程',
			isSame: true,
		},
	},
	{
		path: '/projectDetail',
		name: 'projectDetail',
		component: () => import('@/views/project/detail.vue'),
		meta: {
			title: '精品工程',
			isSame: true,
			parent: '/project',
		},
	},
]
export default companyProfile
