const getDateMon = (str) => {
	if (str.indexOf(' ') !== -1) {
		return (
			str.split(' ')[0].split('-')[0] +
			'.' +
			str.split(' ')[0].split('-')[1]
		)
	}
}
const getDate = (str) => {
	if (str.indexOf(' ') !== -1) {
		return str.split(' ')[0].split('-')[2]
	}
}
export { getDateMon, getDate }
