const companyProfile = [
	{
		path: '/culture',
		name: 'culture',
		component: () => import('@/views/culture/index.vue'),
		meta: {
			title: '企业文化',
			isSame: true,
		},
	},
	{
		path: '/cultureDetail',
		name: 'cultureDetail',
		component: () => import('@/views/culture/detail.vue'),
		meta: {
			title: '企业文化',
			isSame: true,
			parent: '/culture',
		},
	},
]
export default companyProfile
