const companyProfile = [
	{
		path: '/news',
		name: 'news',
		component: () => import('@/views/news/index.vue'),
		meta: {
			title: '新闻动态',
			isSame: true,
		},
	},
	{
		path: '/newsDetail',
		name: 'newsDetail',
		component: () => import('@/views/news/detail.vue'),
		meta: {
			title: '新闻动态',
			isSame: true,
			parent: '/news',
		},
	},
]
export default companyProfile
