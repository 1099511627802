const companyProfile = [
	{
		path: '/industry',
		name: 'industry',
		component: () => import('@/views/industry/index.vue'),
		meta: {
			title: '相关产业',
		},
	},
	{
		path: '/industryDetail',
		name: 'industryDetail',
		component: () => import('@/views/industry/detail.vue'),
		meta: {
			title: '相关产业',
			isSame: true,
		},
	},
]
export default companyProfile
