<template>
	<div>
		<header @mouseleave="isShow = false">
			<div class="navbar m_flex_box flex_vc flex_jz">
				<router-link to="/">
					<img src="@/assets/img/logo_ls.png" alt="" />
				</router-link>
				<div class="m_flex_box flex_vc">
					<el-menu
						:default-active="activeIndex"
						class="el-menu-demo"
						mode="horizontal"
						active-text-color="#2F66A6"
						text-color="#333"
						:router="true"
						@select="handleSelect"
					>
						<el-menu-item index="/">首页</el-menu-item>
						<el-submenu index="companyProfile">
							<template slot="title">集团概况</template>
							<el-menu-item index="/companyProfile/introduction"
								>集团简介</el-menu-item
							>
							<el-menu-item index="/companyProfile/leader"
								>领导致辞</el-menu-item
							>
							<el-menu-item index="/companyProfile/philosophy"
								>企业理念</el-menu-item
							>
							<el-menu-item index="/companyProfile/framework"
								>组织架构</el-menu-item
							>
							<el-menu-item index="/companyProfile/certificate"
								>企业证书</el-menu-item
							>
							<el-menu-item index="/companyProfile/honer"
								>企业荣誉</el-menu-item
							>
						</el-submenu>
						<el-submenu index="news">
							<template slot="title">新闻动态</template>
							<el-menu-item index="/news?type=1"
								>集团新闻</el-menu-item
							>
							<el-menu-item index="/news?type=2"
								>行业动态</el-menu-item
							>
							<el-menu-item index="/news?type=3"
								>通知公告</el-menu-item
							>
						</el-submenu>
						<el-submenu index="project">
							<template slot="title">精品工程</template>
							<el-menu-item index="/project?type=1"
								>获奖工程</el-menu-item
							>
							<el-menu-item index="/project?type=2"
								>精品展示</el-menu-item
							>
						</el-submenu>
						<el-submenu index="culture">
							<template slot="title">企业文化</template>
							<el-menu-item index="/culture?type=1"
								>文化建设</el-menu-item
							>
							<el-menu-item index="/culture?type=2"
								>员工风采</el-menu-item
							>
						</el-submenu>
						<el-menu-item index="/resources">人力资源</el-menu-item>
						<el-submenu index="industry">
							<template slot="title">相关产业</template>
							<el-menu-item
								v-for="(item) in bussnessList"
								:key="item.id"
								:index="`/industryDetail?type=${item.id}`"
								>{{ item.title }}</el-menu-item
							>
						</el-submenu>
						<el-menu-item index="/contact">联系我们</el-menu-item>
					</el-menu>
					<div
						class="search_main flex_vc m_flex_box"
						@mouseenter="isShow = true"
					>
						<i class="el-icon-search search"></i>
						<div
							class="search_con"
							:style="{ display: isShow ? 'block' : 'none' }"
						>
							<div class="container m_flex_box flex_vc">
								<input
									@keyup.enter="search"
									type="text"
									class="flex-1"
									placeholder="请输入您想搜索的内容"
									v-model="searchKey"
								/>
								<i
									class="el-icon-search search_sm"
									@click="search"
								></i>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
		<div class="header_pad"></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			activeIndex: '/',
			searchKey: '',
			isShow: false,
      bussnessList: []
		}
	},
	watch: {
		$route: {
			handler(to, from) {
				if (to.meta.isSame) {
					if (to.meta.parent) {
						this.activeIndex = `${to.meta.parent}?type=${to.query.type}`
					} else {
						this.activeIndex = `${to.path}?type=${to.query.type}`
					}
				} else {
					this.activeIndex = to.path
				}
			},
			immediate: true,
		},
	},
  mounted() {
    this.getBussness()
  },
	methods: {
		handleSelect(key, keyPath) {
			console.log(key, keyPath)
		},
    getBussness() {
      this.$http.get('home/xgcy.do', {
        pageNo: 1,
        pageSize: 1000
      }).then(res => {
        this.bussnessList = res.result
      })
    },
		search(e) {
			if (!this.searchKey) {
				return
			}
			console.log(this.searchKey)
			this.isShow = false
			this.$router.push({
				path: '/resultList',
				query: {
					name: this.searchKey,
				},
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.navbar {
	width: 1280px;
	margin: 0 auto;
	height: 80px;
}
header {
	position: fixed;
	z-index: 9;
	top: 0;
	left: 0;
	right: 0;
	background-color: #fff;
	box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
}
.header_pad {
	height: 80px;
}
.search_main {
	position: relative;
	height: 80px;
	padding: 0 20px;
	.search_con {
		position: fixed;
		box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
		left: 0;
		right: 0;
		top: 79px;
		height: 80px;
		background-color: #fff;
		.container {
			border-bottom: 1px solid #3076f5;
			.search_sm {
				font-size: 22px;
				color: #3076f5;
				font-weight: 600;
				cursor: pointer;
			}
		}
		input {
			height: 70px;
			border: none;
			outline: none;
			font-size: 18px;
		}
	}
}
.search {
	font-size: 26px;
	cursor: pointer;
	color: #3076f5;
}
</style>
