import Vue from 'vue'
import VueRouter from 'vue-router'
import RouterConfig from './module'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import('../views/HomeView.vue'),
	},
	{
		path: '/resources',
		name: 'resources',
		component: () => import('../views/resources.vue'),
	},
	{
		path: '/contact',
		name: 'contact',
		component: () => import('../views/contact.vue'),
	},
	{
		path: '/resultList',
		name: 'resultList',
		component: () => import('../views/resultList.vue'),
	},
	...RouterConfig,
]
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	scrollBehavior: (to, from, savedPosition) => {
		if ((savedPosition || to.meta.y) && store.getters.hasView.length) {
			const y = savedPosition ? savedPosition : to.meta.y
			return { y }
		}
		return { x: 0, y: 0 }
	},
	routes,
})

export default router
